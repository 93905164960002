import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_AUTH_URL;

const SubscriptionSuccess = () => {
  const [status, setStatus] = useState('loading');
  const location = useLocation();

  useEffect(() => {
    const sessionId = new URLSearchParams(location.search).get('session_id');
    if (sessionId) {
      fetch(`${API_BASE_URL}/verify-subscription?session_id=${sessionId}`)
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            setStatus('success');
          } else {
            setStatus('error');
          }
        })
        .catch(() => setStatus('error'));
    }
  }, [location]);

  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (status === 'error') {
    return <div>There was an error processing your subscription. Please try again.</div>;
  }

  return (
    <div>
      <h2>Subscription Successful!</h2>
      <p>Thank you for subscribing. Your account has been upgraded.</p>
    </div>
  );
};

export default SubscriptionSuccess;