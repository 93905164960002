import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToHashSection() {
  const location = useLocation();

  useEffect(() => {
    // Check if there's a hash in the URL
    if (location.hash) {
      // Remove the '#' from the hash
      const id = location.hash.substring(1);
      
      // Find the element with the id
      const element = document.getElementById(id);
      
      if (element) {
        // Scroll to the element
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // If no hash, scroll to top of the page
      window.scrollTo(0, 0);
    }
  }, [location]); // Re-run the effect when the location changes

  // This component doesn't render anything
  return null;
}

export default ScrollToHashSection;