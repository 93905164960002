import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { subDays, subMonths, subYears, startOfYear, parseISO, differenceInDays } from 'date-fns';
import Input from '../components/ui/Input';
import Button from '../components/ui/Button';
import { Card, CardTitle, CardContent } from '../components/ui/Card';
import { findAds, getAdMetrics } from '../services/api';
import NavbarLogged from '../components/NavbarLogged';
import ErrorMessage from '../components/ui/ErrorMessage';
import AdMetricsChart from '../components/AdMetricsChart';
import AdAnalytics from '../components/AdAnalytics';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import AdTable from '../components/AdTable';
import Spinner from '../components/ui/Spinner';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import Footer from '../components/Footer';


const AdsPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [domain, setDomain] = useState(state?.domain || '');
  const [ads, setAds] = useState({ total_ads: [], ads: [] });
  const [adMetrics, setAdMetrics] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedView, setSelectedView] = useState('daily');
  const [selectedTimeRange, setSelectedTimeRange] = useState('1year');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filters, setFilters] = useState({
    status: 'all',
    firstShowed: '',
    lastShowed: '',
    campaignDuration: ''
  });
  const [metrics, setMetrics] = useState({
    growthPercentage: 0,
    averageCampaignDuration: 0,
    adRotationRate: 0
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [adsPerPage] = useState(10);
  const [isLoadingAds, setIsLoadingAds] = useState(false);
  const [isLoadingMetrics, setIsLoadingMetrics] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  const COLORS = ['#cd3035', '#F09D51', '#06ad13', '#008000', '#e31829'];

  const fetchData = useCallback(async (domain) => {
    setIsLoadingAds(true);
    setIsLoadingMetrics(true);
    setError(null);
    setHasSearched(true);

    try {
      // First, fetch ads data
      const adsResult = await findAds(domain);
      
      if (!adsResult || adsResult.length === 0) {
        throw new Error('No ads data available for this domain.');
      }

      setAds({
        raw_ads: adsResult,
        ads: adsResult
      });
      setIsLoadingAds(false);

      // Then, use the ads result to fetch metrics
      const metricsResult = await getAdMetrics(domain, adsResult);

      setAdMetrics(metricsResult);
      
      // Calculate average campaign duration
      const totalDuration = adsResult.reduce((sum, ad) => {
        if (ad.first_showed && ad.last_showed) {
          const startDate = new Date(ad.first_showed);
          const endDate = new Date(ad.last_showed);
          return sum + Math.round((endDate - startDate) / (1000 * 60 * 60 * 24)); // Convert to days
        }
        return sum;
      }, 0);
      const averageCampaignDuration = adsResult.length > 0 ? totalDuration / adsResult.length : 0;

      const calculatedMetrics = calculateMetrics(metricsResult, selectedTimeRange);
      setMetrics({
        ...calculatedMetrics,
        averageCampaignDuration: Math.round(averageCampaignDuration)
      });
      setIsLoadingMetrics(false);

    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message || 'An error occurred while fetching data.');
    } finally {
      setIsLoadingAds(false);
      setIsLoadingMetrics(false);
    }
  }, [selectedTimeRange]);

  const handleInputChange = (e) => setDomain(e.target.value);
  const handleSearch = () => domain && fetchData(domain);

  useEffect(() => {
    if (domain) {
      const authToken = localStorage.getItem('access_token');
      if (!authToken) {
        navigate('/auth');
      }
    }
  }, [domain, navigate]);

  const filterDataByTimeRange = (entries) => {
    const now = new Date();
    let startDate;

    switch (selectedTimeRange) {
      case '5days':
        startDate = subDays(now, 5);
        break;
      case '1month':
        startDate = subMonths(now, 1);
        break;
      case 'YTD':
        startDate = startOfYear(now);
        break;
      case '1year':
        startDate = subYears(now, 1);
        break;
      case '2years':
        startDate = subYears(now, 2);
        break;
      case '5years':
        startDate = subYears(now, 5);
        break;
      case 'all':
      default:
        return entries;
    }

    return entries.filter(([date]) => {
      if (selectedView === 'weekly') {
        return parseISO(date.split('-W')[0] + '-01') >= startDate;
      } else if (selectedView === 'yearly') {
        return parseISO(date + '-01-01') >= startDate;
      }
      return new Date(date) >= startDate;
    });
  };

  const prepareChartData = (data) => {
    if (!data || typeof data !== 'object') {
      console.warn('Invalid data provided to prepareChartData:', data);
      return [];
    }

    const sortedEntries = Object.entries(data).sort(([a], [b]) => new Date(a) - new Date(b));
    const filteredEntries = filterDataByTimeRange(sortedEntries);
    return filteredEntries.map(([date, count]) => ({ date, count }));
  };

  const timeRangeOptions = [
    { value: '5days', label: '5D' },
    { value: '1month', label: '1M' },
    { value: 'YTD', label: 'YTD' },
    { value: '1year', label: '1Y' },
    { value: '2years', label: '2Y' },
    { value: '5years', label: '5Y' },
    { value: 'all', label: 'ALL' },
  ];

  const sortedAndFilteredAds = useMemo(() => {
    if (!ads.ads) return [];
    let filteredAds = [...ads.ads];

    // Apply filters
    if (filters.status !== 'all') {
      filteredAds = filteredAds.filter(ad => {
        if (!ad.last_showed) return false;
        const lastShowedDate = new Date(ad.last_showed);
        return filters.status === 'active' ? lastShowedDate > new Date() : lastShowedDate <= new Date();
      });
    }
    if (filters.firstShowed) {
      filteredAds = filteredAds.filter(ad => {
        if (!ad.first_showed) return false;
        return new Date(ad.first_showed) >= new Date(filters.firstShowed);
      });
    }
    if (filters.lastShowed) {
      filteredAds = filteredAds.filter(ad => {
        if (!ad.last_showed) return false;
        return new Date(ad.last_showed) <= new Date(filters.lastShowed);
      });
    }
    if (filters.campaignDuration) {
      filteredAds = filteredAds.filter(ad => {
        if (!ad.campaign_duration) return false;
        return ad.campaign_duration >= parseInt(filters.campaignDuration);
      });
    }

    // Apply sorting
    if (sortConfig.key) {
      filteredAds.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredAds;
  }, [ads.ads, sortConfig, filters]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? <FaSortUp className="inline ml-1" /> : <FaSortDown className="inline ml-1" />;
    }
    return <FaSort className="inline ml-1" />;
  };
  
  const getFaviconUrl = (domain) => {
    return `https://www.google.com/s2/favicons?domain=${domain}&sz=32`;
  };

  const calculateMetrics = useCallback((metricsResult, selectedTimeRange) => {
    const chartData = prepareChartData(metricsResult.timeline);
    
    if (chartData.length < 2) {
      return {
        growthPercentage: 0,
        averageCampaignDuration: 0,
        adRotationRate: 0
      };
    }

    const startValue = chartData[0].count;
    const endValue = chartData[chartData.length - 1].count;
    const growthPercentage = startValue !== 0 ? ((endValue - startValue) / startValue) * 100 : 0;

    // Calculate average campaign duration
    let averageCampaignDuration = 0;
    if (ads && ads.raw_ads && ads.raw_ads.length > 0) {
      const totalDuration = ads.raw_ads.reduce((sum, ad) => {
        if (ad.first_showed && ad.last_showed) {
          const startDate = new Date(ad.first_showed);
          const endDate = new Date(ad.last_showed);
          return sum + (endDate - startDate) / (1000 * 60 * 60 * 24); // Convert to days
        }
        return sum;
      }, 0);
      averageCampaignDuration = totalDuration / ads.raw_ads.length;
    }

    // Calculate ad rotation rate
    const firstDate = new Date(chartData[0].date);
    const lastDate = new Date(chartData[chartData.length - 1].date);
    const totalDays = (lastDate - firstDate) / (1000 * 60 * 60 * 24);
    const adRotationRate = totalDays > 0 ? (endValue / totalDays) * 30 : 0; // Ads per month

    return {
      growthPercentage,
      averageCampaignDuration,
      adRotationRate
    };
  }, [ads, prepareChartData]);

  useEffect(() => {
    if (adMetrics) {
      const calculatedMetrics = calculateMetrics(adMetrics, selectedTimeRange);
      setMetrics(calculatedMetrics);
    }
  }, [adMetrics, selectedTimeRange, calculateMetrics]);

  const renderContent = () => {
    return (
      <>
        <NavbarLogged />
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-4xl font-bold mb-8 text-primary">Search Competitor Ads</h1>
          
          <div className="mb-12">
            <div className="flex flex-col sm:flex-row gap-4 items-start">
              <Input
                type="text"
                value={domain}
                onChange={handleInputChange}
                placeholder="Enter competitor domain (e.g., competitor.com)"
                variant="search"
                className="w-full sm:w-1/4 p-2 rounded-md border border-text_light" 
              />
              <Button
                onClick={handleSearch}
                variant="primary"
                className="mt-2 sm:mt-0"
              >
                {isLoadingAds ? 'Searching...' : 'Search ads'}
              </Button>
            </div>
          </div>

          {error && <ErrorMessage message={error} />}

          {isLoadingMetrics ? (
            <div className="flex justify-center items-center h-64">
              <Spinner />
            </div>
          ) : (
            <>
              {adMetrics && hasSearched ? (
                <Card variant="result" className="mb-8">
                  <CardTitle className="mb-8 text-center flex items-center justify-center">
                    <img src={getFaviconUrl(domain)} alt={`${domain} favicon`} className="mr-2 w-6 h-6" />
                    {domain}
                  </CardTitle>
                  <CardContent className="w-full">
                    <div className="flex flex-wrap gap-4 mb-4 justify-left">
                      <div>
                        <label htmlFor="view-selector" className="mr-2 text-text_light">View:</label>
                        <select
                          id="view-selector"
                          value={selectedView}
                          onChange={(e) => setSelectedView(e.target.value)}
                          className="border border-border rounded p-1 text-text_dark bg-white focus:outline-none "
                        >
                          <option value="daily">Daily</option>
                          <option value="weekly">Weekly</option>
                          <option value="monthly">Monthly</option>
                          <option value="yearly">Yearly</option>
                        </select>
                      </div>
                      <div className="flex items-center">
                        <span className="mr-2 text-text_dark">Time Range:</span>
                        <div className="flex">
                          {timeRangeOptions.map((option, index) => (
                            <button
                              key={option.value}
                              onClick={() => setSelectedTimeRange(option.value)}
                              className={`px-3 py-1 text-sm ${
                                selectedTimeRange === option.value
                                  ? 'bg-primary text-text_light'
                                  : 'bg-white text-text_dark hover:bg-gray-100'
                              } focus:outline-none ${
                                index === 0 ? 'border-l border-t border-b rounded-l' : 
                                index === timeRangeOptions.length - 1 ? 'border-r border-t border-b rounded-r' : 
                                'border-t border-b'
                              } border-border`}
                            >
                              {option.label}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-4 mb-8">
                      <div className="w-full md:w-1/3">
                        <h3 className="text-xl font-semibold mb-4 text-text_dark text-center">
                          Number of different ads showed over time
                        </h3>
                        {adMetrics.timeline ? (
                          <AdMetricsChart data={prepareChartData(adMetrics.timeline)} />
                        ) : (
                          <p className="text-center text-text_light">No ad metrics data available for this domain.</p>
                        )}
                      </div>
                      <div className="w-full md:w-1/3">
                        <h3 className="text-xl font-semibold mb-4 text-text_dark text-center">
                          Ad Formats Distribution
                        </h3>
                        <ResponsiveContainer width="100%" height={200}>
                          <PieChart>
                            <Pie
                              data={Object.entries(adMetrics.ad_formats).map(([key, value]) => ({ name: key, value: value }))}
                              cx="50%"
                              cy="50%"
                              labelLine={false}
                              outerRadius={80}
                              innerRadius={40}
                              fill="#8884d8"
                              dataKey="value"
                            >
                              {Object.entries(adMetrics.ad_formats).map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                            </Pie>
                            <Tooltip contentStyle={{ backgroundColor: '#FFFFFF', borderColor: '#201e21', borderRadius: '8px' }} />
                            <Legend
                              wrapperStyle={{
                                color: '#201e21',
                                fontFamily: 'Outfit, sans-serif',
                              }}
                            />
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                      <div className="w-full md:w-1/3">
                        <AdAnalytics 
                          currentAdCount={adMetrics ? adMetrics.total_ads : 0}
                          growthPercentage={metrics.growthPercentage}
                          averageCampaignDuration={metrics.averageCampaignDuration}
                          adRotationRate={metrics.adRotationRate}
                          adFormats={adMetrics ? adMetrics.ad_formats : {}}
                        />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ) : hasSearched ? (
                <p className="text-center text-text_light">No ad metrics data available for this domain.</p>
              ) : null}
            </>
          )}

          {isLoadingAds ? (
            <div className="flex justify-center items-center h-64">
            </div>
          ) : (
            <>
              {hasSearched && (
                <>
                  {ads && ads.ads && ads.ads.length > 0 ? (
                    <AdTable
                      ads={sortedAndFilteredAds} 
                      filters={filters}
                      handleFilterChange={handleFilterChange}
                      requestSort={requestSort}
                      getSortIcon={getSortIcon}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      adsPerPage={adsPerPage}
                    />
                  ) : (
                    <p className="text-center text-text_light">No ads data available for this domain.</p>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <Footer />
      </>
    );
  };

  return renderContent();
};

export default AdsPage;