import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getCompetitorList, getAggregateListAds } from '../services/api';
import Button from './ui/Button';
import ErrorMessage from './ui/ErrorMessage';
import Spinner from './ui/Spinner';
import AdMetricsChart from './AdMetricsChart';

const CompetitorListDetail = () => {
  const { id } = useParams();
  const [list, setList] = useState(null);
  const [aggregateData, setAggregateData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchListDetails();
  }, [id]);

  const fetchListDetails = async () => {
    setLoading(true);
    try {
      const listData = await getCompetitorList(id);
      setList(listData);
      const aggregateData = await getAggregateListAds(id);
      setAggregateData(aggregateData);
    } catch (err) {
      setError('Failed to fetch list details');
    }
    setLoading(false);
  };

  if (loading) return <Spinner />;
  if (error) return <ErrorMessage message={error} />;
  if (!list) return null;

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-4">{list.name}</h2>
      <h3 className="text-xl font-semibold mb-2">Domains:</h3>
      <ul className="mb-6">
        {list.domains.map((domain) => (
          <li key={domain}>{domain}</li>
        ))}
      </ul>
      <h3 className="text-xl font-semibold mb-2">Aggregated Ad Data:</h3>
      {aggregateData && (
        <div>
          <AdMetricsChart data={aggregateData.timeline} />
          <div className="mt-4">
            <p>Total Ads: {aggregateData.total_ads}</p>
            <p>Active Ads: {aggregateData.active_ads}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompetitorListDetail;