import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import Spinner from './ui/Spinner';
import ErrorMessage from './ui/ErrorMessage';

const API_BASE_URL = process.env.REACT_APP_API_AUTH_URL;

const fetchSubscription = async (sessionId) => {
  console.log('Fetching subscription with sessionId:', sessionId);
  const response = await axios.get(`${API_BASE_URL}/verify-subscription`, {
    params: { session_id: sessionId },
    withCredentials: true
  });
  console.log('Subscription data received:', response.data);
  return response.data;
};

const CurrentSubscription = ({ onSubscriptionFetched }) => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sessionId, setSessionId] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionIdFromUrl = urlParams.get('session_id');
    console.log('Session ID from URL:', sessionIdFromUrl);
    if (sessionIdFromUrl) {
      setSessionId(sessionIdFromUrl);
    } else {
      setLoading(false);
      setError('No session ID available. Please complete the checkout process.');
    }
  }, []);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      if (!sessionId) return;

      try {
        console.log('Fetching subscription data...');
        setLoading(true);
        const data = await fetchSubscription(sessionId);
        console.log('Subscription data set:', data);
        setSubscription(data);
        onSubscriptionFetched(data);
      } catch (err) {
        console.error('Error fetching subscription:', err);
        setError(err.response?.data?.error || 'Failed to fetch subscription data');
      } finally {
        console.log('Setting loading to false');
        setLoading(false);
      }
    };

    fetchSubscriptionData();
  }, [sessionId, onSubscriptionFetched]);

  console.log('Current state:', { loading, error, subscription });

  if (loading) return <div className="flex justify-start items-center"><Spinner /></div>;
  if (error) return <ErrorMessage message={error} />;

  return (
    <Card className="bg-white shadow-lg rounded-lg overflow-hidden">
      <CardHeader className="text-text_dark p-4">
        <CardTitle className="text-xl font-bold">Your Current Subscription</CardTitle>
      </CardHeader>
      <CardContent className="p-4">
        {subscription ? (
          <div className="space-y-2">
            <p className="text-text_dark"><span className="font-semibold">Current Plan:</span> {subscription.plan}</p>
            <p className="text-text_dark"><span className="font-semibold">Status:</span> {subscription.status}</p>
            <p className="text-text_dark">
              <span className="font-semibold">Expiration:</span> {
                subscription.current_period_end 
                  ? new Date(subscription.current_period_end * 1000).toLocaleDateString()
                  : 'No active subscription period'
              }
            </p>
          </div>
        ) : (
          <p className="text-text_dark">No subscription data available</p>
        )}
      </CardContent>
    </Card>
  );
};

export default CurrentSubscription;
