import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function AuthCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      console.log('AuthCallback: Starting callback handling');
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const encodedData = urlParams.get("data");

        console.log('AuthCallback: URL parameters:', {
          encodedData: encodedData ? 'present' : 'absent',
          fullUrl: window.location.href
        });

        if (!encodedData) {
          console.error('AuthCallback: Missing data in URL');
          navigate('/auth');
          return;
        }

        const userData = JSON.parse(decodeURIComponent(encodedData));

        if (!userData.access || !userData.refresh) {
          console.error('AuthCallback: Missing access or refresh token in data');
          navigate('/auth');
          return;
        }

        // Store the access and refresh tokens
        localStorage.setItem('access_token', userData.access);
        localStorage.setItem('refresh_token', userData.refresh);
        console.log('AuthCallback: Successfully stored access and refresh tokens');

        // Store user data in local storage
        const userInfo = {
          email: userData.email,
          name: userData.name,
          surname: userData.surname,
          profile_picture: userData.profile_picture
        };
        localStorage.setItem('user', JSON.stringify(userInfo));
        console.log('AuthCallback: Successfully stored user data', userInfo);

        // Navigate to the next page
        navigate('/competitors');
      } catch (error) {
        console.error('AuthCallback: Error during authentication:', error);
        navigate('/auth');
      }
    };

    handleCallback();
  }, [navigate]);

  return <div>Processing authentication...</div>;
}

export default AuthCallback;