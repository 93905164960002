import React, { useEffect, useRef } from 'react';
import Navbar from '../components/Navbar';
import LandingFirstSection from '../components/LandingFirstSection';
import ReviewSection from '../components/ReviewSection';
import UseCasesSection from '../components/UseCasesSection';
import PricingSection from '../components/PricingSection';
import Footer from '../components/Footer';

const HomePage = () => {
  const mainRef = useRef(null);
  const footerRef = useRef(null);

  useEffect(() => {
    const logSizes = () => {
      if (mainRef.current) {
        console.log('Main content height:', mainRef.current.offsetHeight);
        console.log('Footer height:', footerRef.current ? footerRef.current.offsetHeight : 'N/A');
        console.log('Viewport height:', window.innerHeight);
        console.log('Document height:', document.documentElement.scrollHeight);
      }
    };

    logSizes();
    window.addEventListener('resize', logSizes);
    return () => window.removeEventListener('resize', logSizes);
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <Navbar />
      <main className="flex-grow">     
       <LandingFirstSection/>
        <ReviewSection />
        <section id="use-cases">
          <UseCasesSection/>
        </section>
        <section id="pricing">
          <PricingSection />
        </section> 
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;