const API_BASE_URL = process.env.REACT_APP_API_AUTH_URL;

// Get the access token from localStorage
const getAccessToken = () => localStorage.getItem('access_token');

// Function to log in the user and store tokens
export const googleLogin = async (token) => {
    try {
      const response = await fetch(`${API_BASE_URL}/google/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `${response.status}: ${response.statusText}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error("Login failed:", error);
      throw error;
    }
  };

export const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) {
        throw new Error('No refresh token found');
    }
    try {
        const response = await fetch(`${API_BASE_URL}/refresh-token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refresh_token: refreshToken }),
        });

        if (!response.ok) {
            throw new Error('Failed to refresh token');
        }

        const data = await response.json();
        localStorage.setItem('access_token', data.access_token);
        return data.access_token;
    } catch (error) {
        console.error('Failed to refresh token:', error);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        window.location.href = '/auth';
        throw error;
    }
};

export const isTokenExpired = (token) => {
    if (!token) return true;
    try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        const currentTime = Math.floor(Date.now() / 1000);
        return payload.exp < currentTime;
    } catch (error) {
        console.error('Error decoding token:', error);
        return true;
    }
};

// Function to ensure valid access token
export const ensureValidAccessToken = async () => {
    const token = getAccessToken();
    if (!token || isTokenExpired(token)) {
        return await refreshAccessToken(); // Refresh token if expired or not present
    }
    return token;
};

const userServices = { googleLogin, refreshAccessToken, ensureValidAccessToken, isTokenExpired, getAccessToken };
export default userServices;