import React from 'react';
import { classNames } from '../../utils/classNames';
import { FaArrowRight } from 'react-icons/fa';

const CTAButton = ({ children, variant = 'primary', className, ...props }) => {
  const baseClasses = 'px-6 py-3 font-semibold rounded-md transition duration-300 ease-in-out flex items-center justify-center';
  
  const variantClasses = {
    primary: 'bg-primary text-text_light hover:bg-opacity-90 group',
    secondary: 'bg-text_dark text-text_light  hover:bg-opacity-80',
    tertiary: 'bg-transparent text-text_dark px-0 py-0 font-normal hover:underline'
  };

  const buttonClasses = classNames(
    baseClasses,
    variantClasses[variant],
    className
  );

  return (
    <button
      className={buttonClasses}
      {...props}
    >
      {children}
      {variant === 'primary' && (
        <FaArrowRight className="ml-2 transition-transform duration-300 ease-in-out group-hover:translate-x-2" />
      )}
    </button>
  );
};

export default CTAButton;