import React, { useState } from 'react';
import { FaExclamationCircle, FaTimes } from 'react-icons/fa';

const ErrorMessage = ({ message }) => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className="fixed right-5 bottom-5 flex items-center bg-red-600 text-white p-3 rounded-md shadow-md max-w-3/5">
      <FaExclamationCircle className="flex-shrink-0 mr-3 text-l" />
      <span className="mr-3 text-xs">{message}</span>
      <button
        onClick={() => setIsVisible(false)}
        className="ml-auto text-white hover:text-gray-200 focus:outline-none"
      >
        <FaTimes className="text-l" />
      </button>
    </div>
  );
};

export default ErrorMessage;