import React from 'react';
import { FaArrowRight } from 'react-icons/fa';

const Button = ({ children, className, showArrow = true, ...props }) => (
  <button
    className={`
      bg-primary text-text_light px-3 py-2 rounded cursor-pointer text-base
      transition-all duration-500 ease-in-out flex items-center justify-center
      border border-primary max-w-[300px]
      hover:bg-opacity-80
      ${className}
    `}
    {...props}
  >
    {children}
    {showArrow && <FaArrowRight className="ml-2 text-lg" />}
  </button>
);

export default Button;
