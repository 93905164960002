import React, { useState, useEffect } from 'react';
import NavbarLogged from '../components/NavbarLogged';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/Card';
import SubscriptionManager from '../components/SubscriptionManager';
import CurrentSubscription from '../components/CurrentSubscription';
import Footer from '../components/Footer';


const ProfilePage = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userString = localStorage.getItem('user');
    if (userString) {
      setUser(JSON.parse(userString));
    }
  }, []);

  if (!user) {
    return <div>Loading...</div>;
  }

  const hasPaidPlan = ['BASIC', 'PRO', 'ENTERPRISE'].includes(user.paying_plan);

  return (
    <>
      <NavbarLogged />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-8 text-primary">Profile information</h1>
        <Card className="w-full mx-auto">
          <CardContent>
            <div className="space-y-4 justify-start">
              <ProfileField label="picture" value={`${user.profile_picture}`} />
              <ProfileField label="Name" value={`${user.name} ${user.surname}`} />
              <ProfileField label="Email" value={user.email} />
              {user.phone_number && (
                <ProfileField label="Phone Number" value={user.phone_number} />
              )}
              {hasPaidPlan && <CurrentSubscription />}
              <SubscriptionManager />
            </div>
          </CardContent>
        </Card>
      </div>
      <Footer />
    </>
  );
};

const ProfileField = ({ label, value }) => (
  <div>
    <span className="font-semibold">{label}:</span> {value}
  </div>
);

export default ProfilePage;
