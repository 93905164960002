import React from 'react';
import Footer from '../components/Footer';

const NotFoundPage = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-background">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-error">404</h1>
        <p className="mt-4 text-lg text-text_light">Oops! The page you are looking for does not exist.</p>
        <a href="/" className="mt-6 inline-block px-4 py-2 text-white bg-primary rounded hover:bg-secondary">
          Go to Home
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default NotFoundPage;
