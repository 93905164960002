import userServices from './users/users';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const { ensureValidAccessToken } = userServices;
const getAccessToken = () => localStorage.getItem('access_token');

const performTask = async (endpoint, data, method = 'POST') => {
    await ensureValidAccessToken();
    const token = getAccessToken();

    const requestOptions = {
        method: method,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    };

    if (method === 'POST') {
        requestOptions.body = JSON.stringify(data);
    }

    const response = await fetch(`${API_BASE_URL}${endpoint}`, requestOptions);
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'An error occurred');
    }
    return await response.json();
};

export const findAds = async (domain) => {
    try {
        const response = await performTask('/ads/', { domain }, 'POST');
        return response;
    } catch (error) {
        console.error('Error fetching ads:', error);
        throw error;
    }
};

export const fetchCreativeById = async (advertiserId, adId) => {
    try {
        return await performTask(`/ad-creative/?advertiser_id=${advertiserId}&ad_id=${adId}`, null, 'GET');
    } catch (error) {
        console.error('Error fetching creative data:', error);
        throw error;
    }
};

export const getAdMetrics = async (domain, adsData) => {
    try {
        const response = await performTask('/analytics/ad-analytics/', { domain, ads: adsData }, 'POST');
        return response;
    } catch (error) {
        console.error('Error fetching ad metrics:', error);
        throw error;
    }
};

export const calculateMetrics = (metricsResult, selectedTimeRange) => {
    if (!metricsResult || !metricsResult.timeline) {
        return {
            growthPercentage: 0,
            averageCampaignDuration: 0,
            adRotationRate: 0
        };
    }

    const timeline = metricsResult.timeline;
    const dates = Object.keys(timeline).sort();

    // Calculate growth percentage
    const startCount = timeline[dates[0]] || 0;
    const endCount = timeline[dates[dates.length - 1]] || 0;
    const growthPercentage = startCount === 0 ? 0 : ((endCount - startCount) / startCount) * 100;

    // Average campaign duration is already provided
    const averageCampaignDuration = metricsResult.average_duration || 0;

    // Calculate ad rotation rate
    const totalDays = dates.length;
    const totalAds = metricsResult.total_ads || 0;
    const adRotationRate = totalDays === 0 ? 0 : totalAds / totalDays;

    return {
        growthPercentage: parseFloat(growthPercentage.toFixed(2)),
        averageCampaignDuration: parseFloat(averageCampaignDuration.toFixed(2)),
        adRotationRate: parseFloat(adRotationRate.toFixed(2))
    };
};

export const findCompetitors = async (domain) => {
    try {
        const response = await performTask('/competitors/', { domain }, 'POST');
        return response;
    } catch (error) {
        console.error('Error fetching competitors:', error);
        throw error;
    }
};

export const getCompetitorLists = async () => {
    try {
        return await performTask('/competitor-lists/', null, 'GET');
    } catch (error) {
        console.error('Error fetching competitor lists:', error);
        throw error;
    }
};

export const createCompetitorList = async (name, domains) => {
    try {
        return await performTask('/competitor-lists/', { name, domains }, 'POST');
    } catch (error) {
        console.error('Error creating competitor list:', error);
        throw error;
    }
};

export const getCompetitorList = async (id) => {
    try {
        return await performTask(`/competitor-lists/${id}/`, null, 'GET');
    } catch (error) {
        console.error('Error fetching competitor list:', error);
        throw error;
    }
};

export const updateCompetitorList = async (id, name, domains) => {
    try {
        return await performTask(`/competitor-lists/${id}/`, { name, domains }, 'PUT');
    } catch (error) {
        console.error('Error updating competitor list:', error);
        throw error;
    }
};

export const deleteCompetitorList = async (id) => {
    try {
        return await performTask(`/competitor-lists/${id}/`, null, 'DELETE');
    } catch (error) {
        console.error('Error deleting competitor list:', error);
        throw error;
    }
};

export const getAggregateListAds = async (listId) => {
    try {
        return await performTask(`/aggregate-list-ads/${listId}/`, null, 'GET');
    } catch (error) {
        console.error('Error fetching aggregate list ads:', error);
        throw error;
    }
};
