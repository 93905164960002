import React from 'react';
import { FaPiggyBank, FaTrophy, FaLightbulb, FaChartLine, FaSearch, FaPlus } from 'react-icons/fa';

const FeatureIcon = ({ icon: Icon }) => (
  <div className="rounded-lg md:rounded-xl border-2 rotate-3 bg-white shadow-lg w-12 h-12 md:h-14 md:w-14 border-secondary inline-flex items-center justify-center">
    <div className="text-3xl text-secondary">
      <Icon className="w-6 h-6 md:w-8 md:h-8" />
    </div>
  </div>
);

const UseCasesSection = () => {
  const features = [
    { icon: FaPiggyBank, title: "Save Money" },
    { icon: FaTrophy, title: "Stay Ahead" },
    { icon: FaLightbulb, title: "Plan Wisely" },
    { icon: FaChartLine, title: "Optimize Campaigns" },
    { icon: FaSearch, title: "Market Insights" },
  ];

  const benefits = [
    "Identify cost-effective channels",
    "Track competitors' marketing moves",
    "Create data-driven marketing plans",
    "Learn from successful strategies",
    "Gain insights into target market",
  ];

  return (
    <section className="bg-white flex-1 mx-2 md:mx-auto md:w-full max-w-7xl rounded-xl overflow-hidden" id="use-cases">
      <div className="px-3">
        <div className="space-y-2 px-4 pt-8 md:pt-12 pb-6 md:!py-12 text-center flex flex-col items-center">
          <h2 className="text-xl lg:text-3xl font-semibold text-text_dark">
            How Close Scout Benefits Your Business
          </h2>
          <p className="text-base lg:text-xl max-w-2xl text-text_dark font-regular">
            Optimize ad spend, gain market insights, and make data-driven decisions for your marketing campaigns.
          </p>
        </div>

        <div className="mx-auto max-w-6xl flex flex-col md:flex-row -mb-0.5 pb-5 md:pb-0">
          {features.map((feature, index) => (
            <div key={index} className="flex flex-1 gap-x-2.5 md:flex-col items-center gap-y-2 px-4 md:py-3 py-1">
              <FeatureIcon icon={feature.icon} />
              <span className="text-text_dark font-label font-medium text-center">{feature.title}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-secondary border-t border-text_dark">
        <div className="max-w-5xl px-6 py-8 mx-auto space-y-5">
          <p className="text-xl font-semibold tracking-tight text-text_light">
            Leverage competitor insights to optimize your marketing strategy.
            <br /> We'll help you stay ahead of the competition.
          </p>

          <div className="space-y-2">
            {benefits.map((benefit, index) => (
              <div key={index} className="flex items-center gap-x-2.5">
                <FaPlus className="w-4 h-4 text-text_light" />
                <span className="text-base text-text_dark">{benefit}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default UseCasesSection;
