import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { format, parseISO, isToday, eachDayOfInterval, isBefore, isAfter, startOfDay, endOfDay } from 'date-fns';

const CustomTooltip = ({ active, payload, label, view }) => {
  if (active && payload && payload.length) {
    let formattedDate;
    try {
      let date;
      if (view === 'weekly') {
        const [year, week] = label.split('-W');
        date = parseISO(`${year}-01-01`);
        date.setDate(date.getDate() + (week - 1) * 7);
      } else if (view === 'yearly') {
        date = parseISO(`${label}-01-01`);
      } else {
        date = parseISO(label);
      }
      
      switch (view) {
        case 'daily':
          formattedDate = format(date, 'MMMM d, yyyy');
          break;
        case 'weekly':
          formattedDate = `Week of ${format(date, 'MMMM d, yyyy')}`;
          break;
        case 'monthly':
          formattedDate = format(date, 'MMMM yyyy');
          break;
        case 'yearly':
          formattedDate = format(date, 'yyyy');
          break;
        default:
          formattedDate = label;
      }
    } catch (error) {
      console.error('Error parsing date:', error);
      formattedDate = label;
    }

    return (
      <div className="bg-white border border-border rounded-md p-2 shadow-md w-full">
        <p className="font-semibold text-text_dark">{formattedDate}</p>
        <p className="text-text_dark">
          Ad Count: <span className="font-semibold">{payload[0].value}</span>
        </p>
      </div>
    );
  }

  return null;
};

const AdMetricsChart = ({ data, view, timeRange }) => {
  if (!data || Object.keys(data).length === 0) {
    return <p className="text-text_dark">No data available for the selected view and time range.</p>;
  }

  // Sort the data by date
  const sortedData = [...data].sort((a, b) => new Date(a.date) - new Date(b.date));

  // Get the last date in the data
  const lastDate = new Date(sortedData[sortedData.length - 1].date);
  const today = new Date();

  // If the last date is before today, add missing dates with count 0
  if (isBefore(lastDate, startOfDay(today))) {
    const missingDates = eachDayOfInterval({ start: lastDate, end: today });
    missingDates.slice(1).forEach(date => {
      sortedData.push({ date: format(date, 'yyyy-MM-dd'), count: 0 });
    });
  }

  // Filter out today's data
  const filteredData = sortedData.filter(item => {
    const date = parseISO(item.date);
    return !isToday(date);
  });

  const formatXAxis = (value) => {
    try {
      let date = parseISO(value);
      
      switch (view) {
        case 'weekly':
          return `W${format(date, 'w')}`;
        case 'daily':
        case 'monthly':
        case 'yearly':
        default:
          return format(date, 'MMM yy');
      }
    } catch (error) {
      console.error('Error parsing date:', error);
      return value;
    }
  };

  const getXAxisTickInterval = () => {
    switch (timeRange) {
      case '5days':
        return 1;
      case '1month':
        return 7;
      case 'YTD':
      case '1year':
        return 30;
      case '2years':
      case '5years':
        return 90;
      case 'all':
      default:
        return Math.floor(data.length / 10);
    }
  };

  const showDots = ['5days', '1month'].includes(timeRange) || view === 'yearly';

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={filteredData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke="#c1c1c1" />
        <XAxis
          dataKey="date"
          stroke="#201e21"
          tick={{
            fill: '#201e21',
            fontSize: 16,
            angle: -30,
            textAnchor: 'end',
            dy: 10
          }}
          tickLine={{ stroke: '#201e21' }}
          tickFormatter={formatXAxis}
          interval={getXAxisTickInterval()}
        />
        <YAxis
          stroke="#201e21"
          tick={{ fill: '#201e21', fontSize: 16 }}
          tickLine={{ stroke: '#201e21' }}
        />
        <Tooltip content={<CustomTooltip view={view} />} />
        <Legend
          wrapperStyle={{
            paddingTop: '30px',
            fontSize: '16px',
            color: '#201e21',
          }}
        />
        <Line
          type="monotone"
          dataKey="count"
          name="Ad Count"
          stroke="#cd3035"
          strokeWidth={4}
          dot={showDots ? { fill: '#cd3035', strokeWidth: 2 } : false}
          activeDot={showDots ? { r: 8 } : false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default AdMetricsChart;
