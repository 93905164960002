import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IoLogOut, IoChevronDown } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import logo from '../assets/logo_hz.png';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuItems = [
    { name: 'Use cases', route: '/#use-cases' },
    { name: 'Pricing', route: '/#pricing' }
  ];
  const navigate = useNavigate();

  const userString = localStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : null;

  const userInfo = user ? {
    isLoggedIn: true,
    id: user.id,
    email: user.email,
    name: user.name,
    surname: user.surname,
    phone_number: user.phone_number,
    paying_plan: user.paying_plan,
    profileImage: user.profile_picture || 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png', 
  } : {
    isLoggedIn: false
  };

  const handleNavigation = (route) => {
    if (window.handleNavigation) {
      window.handleNavigation(route);
    } else {
      navigate(route);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user')
    navigate('/');
  };

  return (
    <nav className="flex justify-between items-center px-4 sm:px-8 py-4 bg-white fixed top-0 left-0 right-0 z-10000 border-b border-text_dark h-auto sm:h-[70px]">
      <Link to="/" className="flex-shrink-0">
        <img src={logo} alt="Close Scout" className="h-[50px]" />
      </Link>
      
      <button 
        className="sm:hidden p-2"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
        </svg>
      </button>

      <div className={`${isMenuOpen ? 'flex' : 'hidden'} sm:flex flex-col sm:flex-row w-full sm:w-auto items-center mt-4 sm:mt-0 sm:justify-between sm:flex-grow`}>
        <div className="flex flex-col sm:flex-row gap-4 mb-4 sm:mb-0 sm:mx-auto">
          {menuItems.map((item) => (
            <button 
              key={item.name} 
              className="bg-transparent border-none cursor-pointer text-text_dark py-1 px-3 rounded-full transition-colors duration-300 hover:bg-gray-300 whitespace-nowrap flex items-center"
              onClick={() => handleNavigation(item.route)}
            >
              {item.name}
            </button>
          ))}
        </div>
        <div className="flex items-center">
        {userInfo.isLoggedIn ? (
          <div className="relative flex items-center">
          <div className="text-text_dark mr-4">
            <p className="font-semibold">{userInfo.name} {userInfo.surname}</p>
            <p className="text-sm text-text_dark">{userInfo.email}</p>
          </div>
          <div 
            className="flex items-center cursor-pointer"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <img src={userInfo.profileImage} alt="Profile" className="w-8 h-8 rounded-full" />
            <IoChevronDown className="ml-1 text-primary" />
          </div>
          {isMenuOpen && (
            <div className="absolute right-0 top-full mt-2 w-48 bg-white rounded-md shadow-lg py-1">
              <button 
                onClick={() => handleNavigation('/profile')}
                className="w-full text-left px-4 py-2 text-sm text-text_dark hover:bg-secondary/40 flex items-center cursor-pointer"
              >
                <CgProfile className="mr-2 text-text_dark" />
                Profile
              </button>
              <button 
                onClick={handleLogout}
                className="w-full text-left px-4 py-2 text-sm text-text_dark hover:bg-secondary/40 flex items-center cursor-pointer"
              >
                <IoLogOut className="mr-2 text-error" />
                Log Out
              </button>
            </div>
          )}
        </div>
        ) : (
          <>
            <Link 
              to="/auth"
              className="py-2 px-4 ml-4 border-none rounded cursor-pointer text-base bg-background text-text_light hover:opacity-80 transition duration-100"
            >
              Log In
            </Link>
            <Link 
              to="/auth"
              className="py-2 px-4 ml-4 border-none rounded cursor-pointer text-base bg-primary text-white hover:bg-text_dark transition duration-100"
            >
              Sign Up
            </Link>
          </>
        )}
      </div>
      </div>
    </nav>
  );
}

export default Navbar;
