import React from 'react';
import NavbarLogged from '../components/NavbarLogged';
import CompetitorListManager from '../components/CompetitorListManager';
import Footer from '../components/Footer';


const CompetitorListsPage = () => {
  return (
    <>
      <NavbarLogged />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-8 text-primary">Competitor Lists</h1>
        <CompetitorListManager />
      </div>
      <Footer />
    </>
  );
};

export default CompetitorListsPage;