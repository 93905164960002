import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from './config/stripe';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AuthPage from './pages/AuthPage';
import CompetitorsPage from './pages/CompetitorsPage';
import AdsPage from './pages/AdsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import AuthCallback from './components/AuthCallback';
import CheckoutForm from './pages/CheckoutForm';
import ProfilePage from './pages/ProfilePage';
import ScrollToHashSection from './components/ScrollToHashSection';
import NotFoundPage from './pages/404';
import SubscriptionManager from './components/SubscriptionManager';
import SubscriptionSuccess from './components/SubscriptionSuccess';
import CancelPayment from './components/CancelPayment';
import CompetitorListDetailPage from './pages/CompetitorListDetailPage';
import CompetitorListsPage from './pages/CompetitorListsPage';

const google_token = '963001685375-0412m24kekgco30pbg4rgqbr1rlk8rk9.apps.googleusercontent.com'

function App() {
  return (
    <BrowserRouter>
      <Elements stripe={stripePromise} google_token={google_token}>
        <div className="flex flex-col min-h-screen">
          <main className="flex-grow bg-background pt-[70px]">
            <ScrollToHashSection />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/auth" element={<AuthPage />} />
              <Route path="/competitors" element={<CompetitorsPage />} />
              <Route path="/ads/:slug" element={<AdsPage />} />
              <Route path="/ads" element={<AdsPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
              <Route path="/auth-callback" element={<AuthCallback />} />
              <Route path="/checkout" element={<CheckoutForm />} />
              <Route path="/success" element={<SubscriptionSuccess />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/competitor-lists" element={<CompetitorListsPage />} />
              <Route path="/competitor-lists/:id" element={<CompetitorListDetailPage />} />
              <Route path="/subscribe" element={<SubscriptionManager />} />
              <Route path='/canceled' element={<CancelPayment />} />
              <Route path="*" element={<NotFoundPage />} /> 
            </Routes>
          </main>
        </div>
      </Elements>
    </BrowserRouter>
  );
}

export default App;
