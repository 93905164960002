import React, { useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import Button from './ui/Button';
import ErrorMessage from './ui/ErrorMessage';

const API_BASE_URL = process.env.REACT_APP_API_AUTH_URL;

const SubscriptionManager = ({ subscription }) => {
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubscription = async (plan) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${API_BASE_URL}/create-checkout-session`, 
        { plan },
        { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }
      );
      const { sessionId } = response.data;
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        setError(error.message);
      }
    } catch (error) {
      setError('An error occurred while processing your request.');
    }
    setLoading(false);
  };

  const handleManageSubscription = async (action, plan = null) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${API_BASE_URL}/manage-subscription`,
        { action, plan },
        { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }
      );
      // Handle the response, maybe refresh the current subscription display
      console.log(response.data);
    } catch (error) {
      setError('An error occurred while managing your subscription.');
    }
    setLoading(false);
  };

  return (
    <Card className="bg-white shadow-none rounded-none overflow-hidden border-none">
      <CardHeader className="text-text_dark p-4">
        <CardTitle className="text-xl font-bold">Manage Your Subscription</CardTitle>
      </CardHeader>
      <CardContent className="px-4 space-y-6">
        {subscription && subscription.plan !== 'FREE' ? (
          <div className="space-y-2">
            <h3 className="text-lg font-semibold text-text_dark">Current Plan Actions</h3>
            <div className="flex flex-wrap gap-2">
              <Button 
                onClick={() => handleManageSubscription('upgrade', 'PRO')} 
                disabled={loading}
                showArrow={false}
              >
                Upgrade to Pro
              </Button>
              <Button 
                onClick={() => handleManageSubscription('downgrade', 'BASIC')} 
                disabled={loading}
                showArrow={false}
              >
                Downgrade to Basic
              </Button>
              <Button 
                onClick={() => handleManageSubscription('cancel')} 
                disabled={loading}
                showArrow={false}
              >
                Cancel Subscription
              </Button>
            </div>
          </div>
        ) : (
          <p className='max-w-2xl'>You are currently under the Free plan. If you want to discover how useful this tool can be, you'll have to upgrade your subscription.</p>
        )}

        <div className="space-y-2">
          <h3 className="text-lg font-semibold text-text_dark">Change to...</h3>
          <div className="flex flex-wrap gap-4 justify-center">
            <button 
              onClick={() => handleSubscription('BASIC')} 
              disabled={loading}
              className="text-text_dark underline bg-none"
            >
              Basic
            </button>
            <button 
              onClick={() => handleSubscription('PRO')} 
              disabled={loading}
              className="text-text_dark underline bg-none"
            >
              Pro
            </button>
            <button 
              onClick={() => handleSubscription('ENTERPRISE')} 
              disabled={loading}
              className="text-text_dark underline bg-none"
            >
              Enterprise
            </button>
          </div>
        </div>

        {error && <ErrorMessage message={error} />}
      </CardContent>
    </Card>
  );
};

export default SubscriptionManager;
