import React from 'react';

const Form = ({ children, ...props }) => (
  <form
    className="p-5 rounded-lg flex flex-row md:max-w-full md:p-4"
    {...props}
  >
    {children}
  </form>
);

const FormField = ({ children }) => (
  <div className="mb-2.5 border-none">{children}</div>
);

const FormLabel = ({ children }) => (
  <label className="block mb-2 font-medium text-text_dark">{children}</label>
);

const FormDescription = ({ children }) => (
  <p className="text-sm text-text_dark mt-1">{children}</p>
);

Form.Field = FormField;
Form.Label = FormLabel;
Form.Description = FormDescription;

export default Form;