import React, { useState } from 'react';
import { TbInputSearch, TbSearch } from "react-icons/tb";
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import Input from './ui/Input';
import Form from './ui/Form';
import Button from './ui/Button';
import Separator from './ui/Separator';
import CTAButton from './ui/CTAButton';
import styled from 'styled-components';
import { findAds } from '../services/api';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from './ui/ErrorMessage';

const CompetitorFinderWrapper = styled.div`
  max-width: 500px;
  width: 100%;
  padding: 0 1rem;
`;

function CompetitorFinder() {
  const navigate = useNavigate();
  const [domain, setDomain] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    const authToken = localStorage.getItem('access_token');
    if (!authToken) {
      navigate('/auth');
      setIsLoading(false);
      return;
    }

    try {
      await findAds(domain);
      navigate(`/ads`, { state: { domain } });
    } catch (err) {
      setError(err.message || 'Failed to fetch competitor ads. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CompetitorFinderWrapper className="flex-grow justify-center">
      <Card variant="default" className="p-4 space-y-4 w-full border border-border">
        <div className="flex flex-col sm:flex-row items-center w-full sm:w-11/12 mx-auto">
          <Form onSubmit={handleSubmit} className="w-full flex flex-col sm:flex-row items-center justify-between">
            <div className="flex w-full items-center mb-2 sm:mb-0 sm:mr-2">
              <TbInputSearch className="text-2xl flex-shrink-0 text-secondary mr-2" />
              <Input
                type="text"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
                placeholder={isLoading ? 'Loading...' : 'yourcompetitor.com'}
                disabled={isLoading}
                className="w-full sm:flex-grow rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-secondary"
              />
            </div>
            <Button type="submit" disabled={isLoading} className="w-full sm:w-auto font-medium px-4 py-2 rounded-md">
              Scout!
            </Button>
          </Form>
        </div>

        {error && <ErrorMessage message={error} />}

        <Separator />

        <div className="w-full sm:w-11/12 mx-auto">
          <p className="text-sm text-text_dark text-left">
            Discover all the ads that your competitors are running and gain valuable insights.
          </p>
        </div>
        <CTAButton 
          onClick={() => navigate('/competitors')} 
          className="w-full sm:w-11/12 mx-auto" 
          variant="secondary"
        >
          <TbSearch className="mr-2 text-text_light w-5 h-5" />
          Discover Your Competitors
        </CTAButton>
      </Card>
    </CompetitorFinderWrapper>
  );
}

export default CompetitorFinder;