import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';


const PrivacyPolicyPage = () => {
  return (
    <div className="min-h-screen bg-background">
      <Navbar />
      <div className="container max-w-5/6 mt-20 mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8 text-primary">Privacy Policy</h1>
        <div className="bg-white shadow-lg rounded-lg text-text_dark p-8">
          <p className="text-sm text-gray-500 mb-6">Last updated: Oct 1st 2024</p>
          
          <Section title="1. Introduction">
            <p>Welcome to Close Scout. We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data when you visit our website and tell you about your privacy rights and how the law protects you.</p>
          </Section>
          
          <Section title="2. Data We Collect">
            <p>We may collect, use, store and transfer different kinds of personal data about you, including:</p>
            <ul className="list-disc pl-6 mt-2 space-y-1">
              <li>Identity Data: first name, last name</li>
              <li>Contact Data: email address</li>
              <li>Technical Data: internet protocol (IP) address, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform</li>
              <li>Usage Data: information about how you use our website and services</li>
            </ul>
          </Section>
          
          <Section title="3. How We Use Your Data">
            <p>We use your personal data for the following purposes:</p>
            <ul className="list-disc pl-6 mt-2 space-y-1">
              <li>To provide and maintain our service</li>
              <li>To notify you about changes to our service</li>
              <li>To allow you to participate in interactive features of our service when you choose to do so</li>
              <li>To provide customer support</li>
              <li>To gather analysis or valuable information so that we can improve our service</li>
              <li>To monitor the usage of our service</li>
              <li>To detect, prevent and address technical issues</li>
            </ul>
          </Section>
          
          <Section title="4. Data Retention">
            <p>We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
          </Section>
          
          <Section title="5. Data Security">
            <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know.</p>
          </Section>
          
          <Section title="6. Your Data Protection Rights">
            <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to:</p>
            <ul className="list-disc pl-6 mt-2 space-y-1">
              <li>Request access to your personal data</li>
              <li>Request correction of your personal data</li>
              <li>Request erasure of your personal data</li>
              <li>Object to processing of your personal data</li>
              <li>Request restriction of processing your personal data</li>
              <li>Request transfer of your personal data</li>
              <li>Right to withdraw consent</li>
            </ul>
          </Section>
          
          <Section title="7. Third-Party Links">
            <p>Our website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements.</p>
          </Section>
          
          <Section title="8. Changes to This Privacy Policy">
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this Privacy Policy.</p>
          </Section>
          
          <Section title="9. Contact Us">
            <p>If you have any questions about this privacy policy or our privacy practices, please contact us at: <a href="mailto:p@vermu.com" className="text-primary hover:underline">p@vermu.com</a></p>
          </Section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const Section = ({ title, children }) => (
  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4 text-secondary">{title}</h2>
    <div className="text-text_dark space-y-3">{children}</div>
  </div>
);

export default PrivacyPolicyPage;