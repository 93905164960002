import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { Card } from './ui/Card';
import CTAButton from './ui/CTAButton';
import axios from 'axios';
import { stripePromise } from '../config/stripe';
import ErrorMessage from './ui/ErrorMessage';
import { useNavigate } from 'react-router-dom';
import Spinner from './ui/Spinner';

const API_BASE_URL = process.env.REACT_APP_API_AUTH_URL;

const PricingTier = ({ name, price, discountedPrice, features, isPopular, onSubscribe, isFree }) => (
  <div className="relative h-full">
    {isPopular && (
      <span className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-primary text-text_light px-3 py-1 rounded-full text-sm font-semibold z-10">
        Most Popular
      </span>
    )}
    <Card className={`flex flex-col p-6 h-auto md:h-full md:min-h-[400px] ${isPopular ? 'border-primary' : 'border-border'}`} >
      <h3 className="text-2xl font-bold mb-2">{name}</h3>
      <div className="mb-4 flex flex-col items-start">
        {discountedPrice ? (
          <>
            <span className="text-xl font-bold line-through text-text_dark mb-1">${price.toFixed(2)}</span>
            <div className="flex items-baseline">
              <span className="text-4xl font-bold">${discountedPrice.toFixed(2)}</span>
              <span className="text-secondary ml-1">/month</span>
            </div>
          </>
        ) : (
          <div className="flex items-baseline">
            <span className="text-4xl font-bold">${price.toFixed(2)}</span>
            <span className="text-secondary ml-1">/month</span>
          </div>
        )}
      </div>
      <ul className="mb-6 flex-grow">
        {features.map((feature, index) => (
          <li key={index} className="flex items-start mb-2">
            <FaCheck className="text-primary mr-2 flex-shrink-0 mt-1" />
            <span className="text-sm text-text_dark">{feature}</span>
          </li>
        ))}
      </ul>
      <CTAButton 
        variant={isPopular ? 'primary' : 'secondary'} 
        className="w-full mt-auto"
        onClick={() => onSubscribe(name.toUpperCase(), isFree)}
      >
        {isFree ? 'Get Started' : 'Get Started'}
      </CTAButton>
    </Card>
  </div>
);

const PricingSection = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubscription = async (plan, isFree) => {
    if (isFree) {
      // Check if user is authenticated using localStorage
      const isAuthenticated = localStorage.getItem('user') !== null;
      if (isAuthenticated) {
        navigate('/competitors');
      } else {
        navigate('/auth');
      }
      return;
    }

    setLoading(true);
    setError(null);
    try {
      if (!stripePromise) {
        throw new Error('Stripe is not initialized. Please check your configuration.');
      }
      const stripe = await stripePromise;
      const response = await axios.post(`${API_BASE_URL}/create-checkout-session`, 
        { plan },
        { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }
      );
      const { sessionId } = response.data;
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        console.error('Stripe redirect error:', error);
        setError(`Stripe error: ${error.message}`);
      }
    } catch (error) {
      console.error('Subscription error:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
        setError(`Server error: ${error.response.data.message || error.response.statusText}`);
      } else if (error.request) {
        console.error('Error request:', error.request);
        setError('No response received from the server. Please try again later.');
      } else {
        console.error('Error message:', error.message);
        setError(`An error occurred: ${error.message}`);
      }
    }
    setLoading(false);
  };

  const pricingTiers = [
    {
      name: 'Free',
      price: 0.00,
      features: [
        'Up to 3 competitor domains',
      ],
      isPopular: false,
      isFree: true,
    },
    {
      name: 'Basic',
      price: 19.99,
      discountedPrice: 9.99,
      features: [
        'Up to 10 competitor domains',
        'Weekly ad reports',
        'Analytics',
      ],
      isPopular: false,
      isFree: false,
    },
    {
      name: 'Pro',
      price: 89.99,
      discountedPrice: 49.99,
      features: [
        'Up to 100 competitor domains',
        'Weekly and Monthly ad reports',
        'Analytics',
        'Email notifications',
      ],
      isPopular: true,
      isFree: false,
    },
    {
      name: 'Enterprise',
      price: 1499.99,
      discountedPrice: 1899.99,
      features: [
        'Unlimited competitor domains',
        'Real-time ad tracking',
        'Custom reporting',
        'API access',
        'Dedicated support',
      ],
      isPopular: false,
      isFree: false,
    },
  ];

  return (
    <section className="py-16 bg-background">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-text_light text-center justify-center mb-12">Choose Your Plan</h2>
        {loading && <Spinner />}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {pricingTiers.map((tier, index) => (
            <PricingTier 
              key={index} 
              {...tier} 
              onSubscribe={handleSubscription}
            />
          ))}
        </div>
        {error && <ErrorMessage message={error} />}
      </div>
    </section>
  );
};

export default PricingSection;
