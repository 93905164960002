import React, { useState, useEffect } from 'react';
import { getCompetitorLists, createCompetitorList, deleteCompetitorList } from '../services/api';
import Button from './ui/Button';
import Input from './ui/Input';
import ErrorMessage from './ui/ErrorMessage';
import Spinner from './ui/Spinner';

const CompetitorListManager = () => {
  const [lists, setLists] = useState([]);
  const [newListName, setNewListName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchLists();
  }, []);

  const fetchLists = async () => {
    setLoading(true);
    try {
      const fetchedLists = await getCompetitorLists();
      setLists(fetchedLists);
    } catch (err) {
      setError('Failed to fetch competitor lists');
    }
    setLoading(false);
  };

  const handleCreateList = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await createCompetitorList(newListName, []);
      setNewListName('');
      fetchLists();
    } catch (err) {
      setError('Failed to create competitor list');
    }
    setLoading(false);
  };

  const handleDeleteList = async (id) => {
    setLoading(true);
    try {
      await deleteCompetitorList(id);
      fetchLists();
    } catch (err) {
      setError('Failed to delete competitor list');
    }
    setLoading(false);
  };

  if (loading) return <Spinner />;
  if (error) return <ErrorMessage message={error} />;

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-4">Competitor Lists</h2>
      <form onSubmit={handleCreateList} className="mb-6">
        <Input
          type="text"
          value={newListName}
          onChange={(e) => setNewListName(e.target.value)}
          placeholder="New list name"
          className="mr-2"
        />
        <Button type="submit">Create List</Button>
      </form>
      <ul>
        {lists.map((list) => (
          <li key={list.id} className="flex justify-between items-center mb-2">
            <span>{list.name}</span>
            <Button onClick={() => handleDeleteList(list.id)} variant="danger">Delete</Button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CompetitorListManager;