import React from 'react';
import NavbarLogged from '../components/NavbarLogged';
import CompetitorListDetail from '../components/CompetitorListDetail';
import Footer from '../components/Footer';

const CompetitorListDetailPage = () => {
  return (
    <>
      <NavbarLogged />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-8 text-primary">Competitor List Detail</h1>
        <CompetitorListDetail />
      </div>
      <Footer />
    </>
  );
};

export default CompetitorListDetailPage;