import React, { useState } from 'react';
import Input from '../components/ui/Input';
import Button from '../components/ui/Button';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/Card';
import { findCompetitors } from '../services/api';
import NavbarLogged from '../components/NavbarLogged';
import ErrorMessage from '../components/ui/ErrorMessage';
import { useNavigate } from 'react-router-dom';
import { slugify } from '../utils/helpers'; 
import Spinner from '../components/ui/Spinner';
import { CiCirclePlus } from 'react-icons/ci';
import Footer from '../components/Footer';

const CompetitorsPage = () => {
  const navigate = useNavigate();
  const [domain, setDomain] = useState('');
  const [competitors, setCompetitors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchCompetitors = async () => {
    setLoading(true);
    try {
      const result = await findCompetitors(domain);
      
      if (!result || !Array.isArray(result)) {
        throw new Error('Invalid response from server. Please try again.');
      }

      const sortedCompetitors = result.sort((a, b) => b.ad_count - a.ad_count);

      setCompetitors(sortedCompetitors);
    } catch (error) {
      setError(error.message || 'Failed to find competitors. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCompetitorSearch = (e) => {
    e.preventDefault();
    const authToken = localStorage.getItem('access_token');
    if (!authToken) {
      navigate('/auth');
    } else {
      fetchCompetitors();
    }
  };

  const handleCompetitorClick = (domain) => {
    const slug = slugify(domain);
    navigate(`/ads/${slug}`, { state: { domain } });
  };

  const handleAddToList = (e, domain) => {
    e.stopPropagation(); // Prevent card click event
    // TODO: Implement add to list functionality
    console.log(`Add ${domain} to list`);
  };

  const renderCompetitorCard = (competitor) => (
    <Card
      key={competitor.domain}
      onClick={() => handleCompetitorClick(competitor.domain)}
      className="cursor-pointer hover:shadow-lg transition-shadow duration-300 relative"
    >
      <CardHeader className="w-full">
        <div className="flex items-center justify-start w-full">
          <img
            src={competitor.favicon_url}
            alt={`${competitor.domain} favicon`}
            width={20}
            height={20}
            className="mr-2"
          />
          <CardTitle className="text-xl truncate text-left">{competitor.domain}</CardTitle>
        </div>
      </CardHeader>
      <CardContent className="text-left w-full">
        <p className="text-sm mt-1 text-gray-600 line-clamp-2">{competitor.about}</p>
        {competitor.ad_count > 0 ? (
          <p className="font-semibold mb-2">Total Ads: {competitor.ad_count.toLocaleString()}</p>
        ) : (
          <p className="font-light mb-2">No ads found</p>
        )}
      </CardContent>
      <div 
        className="absolute top-2 right-2 p-1 bg-white rounded-full cursor-pointer hover:bg-gray-100"
        onClick={(e) => handleAddToList(e, competitor.domain)}
        title="Add to list"
      >
        <CiCirclePlus className="text-2xl text-primary" />
      </div>
    </Card>
  );

  return (
    <>
    <NavbarLogged />
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8 text-primary">Find Competitors</h1>
      
      <form onSubmit={handleCompetitorSearch} className="mb-12">
        <div className="flex flex-col sm:flex-row gap-4 items-start">
          <Input
            type="text"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            placeholder="Enter your domain (e.g., yourcompany.com)"
            variant="search"
            className="w-full sm:w-1/4 p-2 rounded-md border border-text_dark"
          />
          <Button 
            type="submit" 
            disabled={loading}
            className="w-full sm:w-auto py-2 px-4 text-md font-semibold"
          >
            {loading ? 'Searching...' : 'Find Competitors'}
          </Button>
        </div>
      </form>

      {loading && (
        <div className="flex justify-center items-center">
          <Spinner /> 
        </div>
      )}

      {!loading && competitors && competitors.length > 0 && (
        <section>
          <h2 className="text-2xl font-bold mb-4 text-text_light">Competitors</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {competitors.map(renderCompetitorCard)}
          </div>
        </section>
      )}

      {!loading && competitors && competitors.length === 0 && (
        <p className="text-center text-text_light">No competitors found with active ads.</p>
      )}

      {error && <ErrorMessage message={error} />}
    </div>
    <Footer />
    </>
  );
};

export default CompetitorsPage;
