import React from 'react';
import { loadStripe } from '@stripe/stripe-js';


function CheckoutForm() {
  const stripe = loadStripe('pk_live_51Q6xD5RuTPDkEIWCL65UE1dSi49tdbaFvO7aSEW8akQkalIcHbMTXpfPGXspp69Km9xEIwgGmbzpRCSmL3VohKai004HX49Yyr');

  const handleClick = async (event) => {
    try {
      const userInfo = JSON.parse(localStorage.getItem('user'));

      const response = await fetch('/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userInfo.id,
          planId: 'pro_plan',
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const session = await response.json();

      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
      });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Checkout error:', error.message);
    }
  };

  return (
    <button id="checkout-button" onClick={handleClick}>
      Subscribe to Pro Plan
    </button>
  );
}

export default CheckoutForm;